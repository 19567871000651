<template>
  <a-drawer
    width="1400"
    :label-col="4"
    :wrapper-col="14"
    :visible="open"
    @close="onClose"
    :destroyOnClose="true">
    <a-divider orientation="left">
      <b>详情</b>
    </a-divider>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="设备编号">
                <a-input v-model="queryParam.devCode" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="水表编号">
                <a-input v-model="queryParam.devSn" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="订单号">
                <a-input v-model="queryParam.orderNo" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="用户手机号">
                <a-input v-model="queryParam.userPhone" placeholder="请输入" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 数据展示 -->
      <a-table
        :components="drag(columns)"
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :scroll="{ x: 2000 }"
        :bordered="tableBordered">
        <span slot="userInfo" slot-scope="text, record">
          <div class="spanBox">
            <span>{{ record.userIdName }}</span>
            <span>{{ record.czUserPhone }}</span>
          </div>
        </span>
        <span slot="bduserInfo" slot-scope="text, record">
          <div class="spanBox">
            <span>{{ record.userName }}</span>
            <span>{{ record.userPhone }}</span>
          </div>
        </span>
        <span slot="payTime" slot-scope="text, record">
          {{ parseTime(record.payTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record"><a @click="onItem(record)">选择</a></span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </a-drawer>
</template>

<script>

import { orderPage, orderDel } from '@/api/pages/payment-refund'
import { tableMixin } from '@/store/table-mixin'
import tableDragResize from '@/utils/tableDragResize'

export default {
  name: 'Config',
  components: {
  },
  mixins: [tableMixin, tableDragResize],
  dicts: ['sys_yes_no'],
  data () {
    return {
      open: false,
      list: [],
      loading: false,
      refreshing: false,
      total: 0,
      // 日期范围
      dateRange: [],
      queryParam: {
        pageNum: 1,
        pageSize: 10,
        orderStatus: 1
      },
      columns: [
        {
          title: '订单号',
          dataIndex: 'orderNo',
          fixed: 'left',
          ellipsis: true,
          width: 160,
          align: 'center'
        },
        {
          title: '水表编号',
          width: 160,
          dataIndex: 'devSn',
          fixed: 'left',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '三方流水号',
          dataIndex: 'orderPayNo',
          ellipsis: true,
          width: 250,
          align: 'center'
        },
        {
          title: '用户信息',
          dataIndex: 'userInfo',
          scopedSlots: { customRender: 'userInfo' },
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '设备名称',
          dataIndex: 'devName',
          ellipsis: true,
          width: 160,
          align: 'center'
        },
        {
          title: '设备编号',
          width: 160,
          dataIndex: 'devCode',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '绑定人信息',
          dataIndex: 'bduserInfo',
          scopedSlots: { customRender: 'bduserInfo' },
          ellipsis: true,
          width: 120,
          align: 'center'
        },
        {
          title: '总金额',
          width: 100,
          dataIndex: 'orderMoney',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '支付时间',
          width: 160,
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '创建时间',
          width: 160,
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          width: 100,
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          fixed: 'right',
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    /** 查询参数列表 */
    getList () {
      this.loading = true
      this.open = true
      orderPage(this.queryParam).then(response => {
          this.list = response.data.records
          this.total = response.data.total
          this.loading = false
        }
      )
    },
    onItem(item) {
      this.open = false
      this.$emit('ok', item)
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.dateRange = []
      this.queryParam = {
        pageNum: 1,
        pageSize: 10,
        orderStatus: 1
      }
      this.handleQuery()
    },
    onClose () {
      this.open = false
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const configIds = row.id ? [row.id] : this.ids
      this.$confirm({
        title: '提示',
        content: '确认删除所选中数据?',
        onOk () {
          return orderDel(configIds)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('wisdom/wisdom-order/export', {
            ...that.queryParam
          }, `水充值订单_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>

<style scoped>
.spanBox{
  display: flex;
  flex-direction: column;
}
</style>
